import "../src/dist/styles.css";
import Landing from "./Pages/Landing";

function App() {
  return (
    <>
      <Landing/>
    </>
      
    
  );
}

export default App;
